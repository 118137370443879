import React from 'react';
import { __app, __fecha } from '../../../../utils/myApp';
import { __pluma7 } from '../../../../Core/pluma7';
import { Numpositivo } from '../../../../Core/MatematicasGlobales';

const Pagos = ({infoPago}) => {


    const suma = (a, b) => a + Numpositivo(b.total);

    const sumarTotal = infoPago.pagos.reduce(suma, 0);

    return (
        <div>
            <p className='text-center'>DOCUMENTOS ABONADOS</p>
            <table className='table'>
                <tr>
                    <th>#Pago</th>
                    <th>Num Factura</th>
                    <th>Fecha de Pago</th>
                    <th>Total Abonado</th>
                    
                </tr>
                      
                { infoPago.pagos.map((pag, index) => 
                    <tr>
                      <td>{pag.id}</td>
                      <td>  { __pluma7.numeroFactura({ numero:pag.ct_numero_factura , id :pag.ct_documento_id }) } </td>
                      <td>{ __fecha.formatoFecha(  {$fecha : pag.CREADO} ) }</td> 
                      <td>{ __app.formato_decimal({total:  Numpositivo(pag.total) , numerosPositivos : true  })}</td>   
                  </tr>  
                )}
            </table>

            <h3>Total Pagado: { __app.formato_decimal({total: Numpositivo(sumarTotal)  , numerosPositivos : true })  }</h3>
          
          
            { (infoPago.saldo_pendiente && infoPago.saldo_pendiente.pendiente) && 
            
                <h3>Nuevo Saldo Pendiente:  { __app.formato_decimal({total: Numpositivo(infoPago.saldo_pendiente.pendiente)  , numerosPositivos : true  })  }  </h3> 
            }
        </div>
    );
};

export default Pagos;