

/**
 * 
 * @returns 
 * Esta funcion calcula el precio Final, 
 * Depues de impuesto, esto facilitara el precio de venta en el cual el usuario quiere vender el producto
 * 
 * El orden de la Validaciones son las siguientes
 * 
 * 
 * 1) Si es Administrador. 
 * Permitir cambiar el precio directo
 * 
 * 
 * ___________________
 * Si es usuario
 * 
 * 1) Validar que el producto permita cambiar precio. Si es asi modificar precio directo
 * 
 * 2) si no aplicarlo como descuento.
 * 
 * 
 * 
 */


import React from 'react';
import { useState } from 'react';

import {__app} from "../../../../utils/myApp";
import { sumarImpuestos } from '../../../../Core/MatematicasGlobales';
import DisplayNumber from './DisplayNumber';

const CambiarPrecioDocumentoFinal = ({item,indexx,accionF,accionName , user ,keyShow, decimal , ct_operacion_id , fontSize}) => {
    
    const [showinput , setShowInput ] = useState(false);


    const handleChange = (e) => {
        setShowInput(false);
        let valor = e.target.value;
        let accion = accionName;

     

        accionF({indexx,valor, accion});
    }


    const _handleKeyDown =  (e)  => {
        if (e.keyCode === 13) {
            handleChange(e);
            e.preventDefault();
            return false;
        } 
    }

    /*bloquear_precio = 0     es   bloqueado */
    if ( user.USER.type_user_id  >  1  && item.bloquear_precio == 1 ){
        
        /*** Si el precio esta blqoqueado  o no es una compra  */
        if(item.bloquear_precio == 1 &&  ct_operacion_id != 2 ){
            return (
                <>    
                   
                
                    <DisplayNumber fontSize={fontSize} number={__app.formato_decimal({"total" : item.MATEMATICAS[keyShow]  , decimal : decimal})}  />

                    <i className="fa-sharp font-s-candado fa-solid fa-lock"></i>
                </>
            )
        }
    }
    
    
    return (
        <>
           { !showinput && 
            <div onClick={ () =>  setShowInput(true) }>   
                <DisplayNumber fontSize={fontSize} number={__app.formato_decimal({"total" : item.MATEMATICAS[keyShow]  , decimal : decimal})}  />
            </div> }
           
           {showinput && <input   onKeyDown={_handleKeyDown} className='max-60' autoFocus onBlurCapture={ handleChange}  defaultValue={item.MATEMATICAS[keyShow]} />}
        

        </>
    );
}

export default CambiarPrecioDocumentoFinal;