import React, { useEffect, useState } from 'react';
import { __app, __fecha } from '../../../utils/myApp';
import { CalcularPorcentaje, Numpositivo } from '../../../Core/MatematicasGlobales';
import Pendiente from '../../Documentos/Componentes/Pendiente';
import StandarModal from '../../Document/PartesDocument/StandarModal';
import VerDocumento from '../../Documentos/VerDocumento/VerDocumento';
import { __pluma7 } from '../../../Core/pluma7';
import { EtiquetaEstadiDian } from '../../../Core/Html/ComponenteHtml';
import NumeroFactura from '../../Documentos/Documentos/Componentes/NumeroFactura';

const TablaDocumentos = ({cargando = null ,  documentos, filtro , campos , getTotales , actualizarDatos }) => {
    
    const [documentosFiltrados, setDocumentosFiltrados] = useState([]);
    
    const [verDocumentoModal , SetverDocumentoModal  ] = useState();
   
    const [Totales, SetTotales ] = useState({
        descuentos : 0,
        precio  : 0,
        abonado : 0, 
    });

    useEffect(() => {


        setDocumentosFiltrados(documentos);
        sumartotales(documentos);


    }, [documentos, filtro]); 


    const sumartotales = (aux) => {
        
        if(!documentos){
            return false;
        }
        let totales = {
            descuentos : 0,
            precio  : 0,
            pendiente : 0, 
            Utilidad : 0
        }

        aux.forEach((data) => {
            

            if (ShowTD('Descuento')) {
                totales.descuentos += Numpositivo(data.dto);
            }
            if (ShowTD('Precio')) {
                totales.precio += Numpositivo(data.sub3);
            }
            if (ShowTD('Pendiente') && data.sp ) {
                totales.pendiente += Numpositivo(data.sp);
            }
            if (ShowTD('Utilidad')) {
                totales.Utilidad += Numpositivo(data.uld);
            }
        });

         
         SetTotales(totales);

    }


    const ShowTD = (td) => {
        return campos.includes(td);
    };

    const [facturaActual, SetfacturaActual] = useState(null);

    const handleVerFactura = (CtDocumento_id) =>{
        SetfacturaActual(CtDocumento_id);
    };

    const handleCerrarModal = () => {
        SetverDocumentoModal(false);
        SetfacturaActual(null);
    };

    useEffect(() => {
        if (facturaActual !== null) {
            SetverDocumentoModal(true);
        } 
    }, [facturaActual]);

    const handleCopyTable = () => {
        let table = document.getElementById('documentTable');
        let range = document.createRange();
        range.selectNode(table);
        window.getSelection().removeAllRanges();  
        window.getSelection().addRange(range);    
        document.execCommand('copy');             
        window.getSelection().removeAllRanges();  
        alert('Tabla copiada, Pegala en Excel!');
    };


    /*** Estrae la clave del json dado */
    const getJson = (json , key ) => {
        
        try {
            return JSON.parse(json)[key];
        } catch (error) {
            console.log(error)
        }
    }


    return (
        <div>
            <button className='btn btn-subtle-secondary' onClick={handleCopyTable}> <i class="fa-regular fa-copy"></i> Copiar Tabla</button>
            <div className="border-y border-translucent mt-5    ">
                <div className="scrollbar ms-n1 ps-1 table-responsive"  style={{ 'overflow': 'auto', 'height': '450px' }}>
                    <table id="documentTable" className="table table-sm border-top border-translucent fs-8 mb-0 table-bordered">
                        <thead>
                            <tr>
                                {ShowTD('#Factura') && <th className="text-end pe-6 sort" style={{ width: '50px' }}>#Factura</th>}
                                
                                {ShowTD('Fecha') && <th className="text-center px-0 sort" style={{ width: '50px' }}>Fecha</th>}
                                
                                {ShowTD('Fecha') && <th className="text-center px-0 sort" style={{ width: '80px', minWidth: '50px' }}>Hora</th>}
                                
                                {ShowTD('PuntoDeVenta') && <th className="text-center sort" style={{ minWidth: '100px', width: '15%' }}>Sede</th>}
                                
                                {ShowTD('Cliente') && <th className="sort text-center" style={{ minWidth: '80px', width: '10%' }}>Cliente</th>}

                                {ShowTD('Usuario') && <th className="sort text-center" style={{ minWidth: '80px', width: '10%' }}>Usuario</th>}

                                {ShowTD('Vendedor') && <th className="sort text-center" style={{ minWidth: '80px', width: '10%' }}>Vendedor</th>}

                                {ShowTD('Estado') && <th className="text-end pe-6 sort" style={{ minWidth: '80px', width: '5%' }}>Estado</th>}
                                
                                {ShowTD('Tipo') && <th className="sort text-center" style={{ minWidth: '80px', width: '10%' }}>Tipo</th>}
                                
                                {ShowTD('Descuento') && <th className="sort text-center" style={{ minWidth: '80px', width: '10%' }}>Descuento</th>}
                                
                                {ShowTD('Precio') && <th className="text-center sort" style={{ minWidth: '120px', width: '15%' }}>Precio</th>}
                                
                                {ShowTD('Pendiente') && <th className="text-center sort" style={{ minWidth: '120px', width: '15%' }}>Pendiente</th>}
                                
                                {ShowTD('Utilidad') && <th className="text-center sort" style={{ minWidth: '120px', width: '15%' }}>Utilidad</th>}
                                
                                {ShowTD('Opciones') && <th className="text-end sort" style={{ minWidth: '50px', width: '15%' }}></th>}
                            </tr>
                        </thead>
                        <tbody>
                            {cargando && <tr><td className='text-center mt-5' colSpan={5}>Cargando...</td></tr>}
                            {!cargando && (
                                <>
                                    {documentosFiltrados && documentosFiltrados.length > 0 ? (
                                        <>
                                            {documentosFiltrados.map((data, index) => (
                                                <tr key={index}>
                                                    
                                            
                                                    
                                                    {ShowTD('#Factura') && <td className="text-start fw-bold text-end pe-6">
                                                        <NumeroFactura id={data.id} numfact={data.numfact}  />  
                                                    </td>}
                                                   
                                                    
                                                    {ShowTD('Fecha') && <td className="text-center">{__fecha.formatoFecha({ $fecha: data.ff , $icon : false })}</td>}
                                                    
                                                    {ShowTD('Fecha') && <td className="text-center">{__fecha.obtenerHora(data.cre)}</td>}
                                                     
                                                    
                                                    {ShowTD('PuntoDeVenta') && <td className="text-center fw-semibold">{ getJson(data.jcj , 'ptv' ) }</td>}
                                                    
                                                    {ShowTD('Cliente') && 
                                                        <td className="text-center fw-semibold text-truncate" style={{ maxWidth: '150px' }}>
                                                            { getJson(data.jcj , 'p_i' ) } -
                                                            { getJson(data.jcj , 'p_nb' ) }
                                                        </td>
                                                    }

                                                    {ShowTD('Usuario') && 
                                                        <td className="text-center fw-semibold text-truncate" style={{ maxWidth: '100px' }}>
                                                            { getJson(data.jcj , 'cjr' ) } 
                                                        </td>
                                                    }


                                                    {ShowTD('Vendedor') && 
                                                        <td className="text-center fw-semibold text-truncate" style={{ maxWidth: '100px' }}>
                                                            { getJson(data.jcj , 'vdor' ) }                                                          
                                                        </td>
                                                    }
                                                     
                                                    
                                                    {ShowTD('Estado') && (
                                                        <td className={`text-start`}>
                                                           
                                                            { __pluma7.EstadoFactura({ estado: data.destd_id, masterId: getJson(data.jcj , 'ctd_mst_id' ) })}    
                                                        
                                                        </td>

                                                    )}
                                                    
                                                    
                                                    {ShowTD('Tipo') && 
                                                        <td className='text-center'>
                                                            <span className={'bg-opacity-75 badge bg-' + getJson(data.jcj , 'ctd_cl' )}>
                                                                {getJson(data.jcj , 'ctd_ec' )}
                                                            </span>
                                        
                                                            <EtiquetaEstadiDian  documentoElectronica={data.DIde} respuesta={data.DIrd}  />                   
                                                        </td>
                                                    }
                                                    
                                                    
                                                    {ShowTD('Descuento') &&    
                                                        <td className="fw-semibold text-center">
                                                            
                                                            {data.dto != 0 ? 
                                                                
                                                                <span className='text-danger'> {__app.formato_decimal({ total: data.dto })} </span> 
                                                                
                                                                : 0
                                                            }
                                                            
                                                            <br />
                                                            
                                                            {data.dto != 0 && (<span className='text-danger'>({__app.formato_decimal({ total: CalcularPorcentaje(data.dto, data.sub1), })}%)</span>)}
                                                   
                                                            
                                                    </td>}
                                                    
                                                    

                                                    {ShowTD('Precio') && <td className="text-center"><span dangerouslySetInnerHTML={{ __html: __app.formato_decimal({ total: data.sub3, color: true, icono: false, numerosPositivos: true }) }}></span></td>}
                                                    
                                                    
                                                    {ShowTD('Pendiente') && <td className="text-center"> <span dangerouslySetInnerHTML={{ __html: __app.formato_decimal({ total: data.sp * -1, color: true, icono: false, numerosPositivos: true }) }}></span> </td>}
                                                    
                                                    
                                                    {ShowTD('Utilidad') && <td className="text-center text-primary "><span className='mt-2'>$ {__app.formato_decimal({ total: data.uld})}</span></td>}
                                                    
                                                    
                                                    {ShowTD('Opciones') && <td className=""><span onClick={() => handleVerFactura(data.id)} className=''> Ver  <i className="fa-solid fa-eye"></i> </span></td>}
                                                </tr>
                                            ))}
                                        </>
                                    ) : (
                                        <tr>
                                            <td colSpan="7" className='text-center'><p className='fw-bolder'>No hay registros para mostrar</p></td>
                                        </tr>
                                    )}
                                </>
                            )}
                        </tbody>
                        <tfoot>
                            <tr>
                                
                                {ShowTD('Fecha') && <th className="text-center px-0 sort" style={{ width: '50px' }}></th>}

                                {ShowTD('Fecha') && <th className="text-center px-0 sort" style={{ width: '90px'}}></th>}
                                
                                {ShowTD('PuntoDeVenta') && <th className="text-center sort" style={{ minWidth: '100px', width: '15%' }}></th>}

                                {ShowTD('Cliente') && <th className="text-center sort" style={{ minWidth: '100px', width: '15%' }}></th>}
                                
                                {ShowTD('Usuario') && <th className="sort text-center" style={{ minWidth: '80px', width: '10%' }}></th>}

                                {ShowTD('Vendedor') && <th className="sort text-center" style={{ minWidth: '80px', width: '10%' }}></th>}

                                {ShowTD('#Factura') && <th className="text-end pe-6 sort" style={{ minWidth: '80px', width: '5%' }}></th>}
                                
                                {ShowTD('Estado') && <th className="text-end pe-6 sort" style={{ minWidth: '80px', width: '5%' }}></th>}
                                
                                {ShowTD('Tipo') && <th className="sort text-center" style={{ minWidth: '80px', width: '10%' }}></th>}
                                
                                {ShowTD('Descuento') && <th className="sort text-danger text-center" style={{ minWidth: '80px', width: '10%' }}>$ {__app.formato_decimal({ total: Totales.descuentos })}</th>}
                                
                                {ShowTD('Precio') && <th className="text-center sort" style={{ minWidth: '120px', width: '15%' }}>$ {__app.formato_decimal({ total: Totales.precio })}</th>}
                                
                                {ShowTD('Pendiente') && <th className="text-center text-danger sort" style={{ minWidth: '120px', width: '15%' }}>$ {__app.formato_decimal({ total: Totales.pendiente })}</th>}
                                
                                {ShowTD('Utilidad') && <th className="text-center text-primary sort" style={{ minWidth: '120px', width: '15%' }}>{__app.formato_decimal({ total: Totales.Utilidad })}</th>}
                                
                                {ShowTD('Opciones') && <th className="text-end sort" style={{ minWidth: '20px', width: '10%' }}></th>}
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>

            <StandarModal keyboard={true} headerShow={true} size="xl" titulo="Ver Factura." desactivar={handleCerrarModal} mostrar={verDocumentoModal}> 
                <VerDocumento document_id={facturaActual} desactivar={handleCerrarModal} PagoExitoso={actualizarDatos} />
            </StandarModal>
        </div>
    );
};

export default TablaDocumentos;
