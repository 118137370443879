
import Dexie from 'dexie';
import { setLocalStore } from './fetchLocalStoreData';





const extraerNombreDataBase = () => {
    const infoData =  JSON.parse( localStorage.getItem("__Ah#d7aWF___") );
    if(infoData){
        return infoData.nit;
    }
    return null;
}

const  nit =  extraerNombreDataBase();



let db = null;
if( nit ){
    db = new Dexie(nit);

}else{
    console.log("La DB no fue creada:" + nit);
    console.log(!nit);
}









export const ExtraerTabla = async (tabla, version = 1, opciones = {}) => {
    // Verifica si la base de datos existe
    if (!db) {
        console.log("Base de datos no inicializada");
        return [];
    }

    // Verifica si la versión actual es diferente a la proporcionada
    if (db.verno !== version) {
        db.version(version).stores({
            productos: 'id',
            terceros: 'id',
            documentos: 'id,fechaidFinal,turno_id',
            actualizaciones: 'id',
            vehiculos: 'id,placa',
        });
    }

    // Verifica si la tabla existe en la base de datos
    if (!db[tabla]) {
        alert("La tabla no existe");
        return false;
    }

    // Si se proporcionan condiciones de búsqueda, realiza la consulta con filtro
    if (opciones.condiciones && opciones.condiciones.where && opciones.condiciones.igual_a !== undefined) {
        let data = await db[tabla]
            .where(opciones.condiciones.where)
            .equals(opciones.condiciones.igual_a)
            .toArray();
        return data;
    } else {
        // Si no se proporcionan condiciones, devuelve todos los registros de la tabla
        let data = await db[tabla].toArray();
        return data;
    }
};






// Función para obtener el último registro ingresado
export const  getUltimoRegistro  = async (tabla , key = 'id') => {
    // Ordenar por 'createdAt' en orden descendente y obtener el primer registro
    return    await db[tabla].orderBy(key).reverse().first();

}




/* Resibe como paarametro una tabla y devuelve sus datos  
   

    La Clave de consulta puede Varias dependiendo la tabla.
    ejemplo para la tabla vehiculos puede que queramos consultar por PLACA
        
*/
export const ExtraerTablabyIds =  async (tabla , ids  ,  clave = 'id'  , version = 1   )  => {
   
   
    if(!db){
        console.log("no debe");
        return [];
    }
    
 

    if (!db.verno == version  ) {
  
        db.version(version).stores({
            productos: 'id',
            terceros: 'id',
            documentos: 'id,fechaidFinal,turno_id',
            actualizaciones: 'id',
            vehiculos : 'id,placa',
        });
    }

    if(!db[tabla]){
        alert("la tabla no existe")
        return false;
    }


	let data = await db[tabla]
    .where(clave)
    .anyOf(  ids )
    .limit( ids.length )
    .toArray()
    .then((results) => {
        
        return results;
    });

    
    return data;
}



/*
*
*  reloadProductos = datos a ingresar en el DXE
*  Table = Tabla del DIXE
    Nuevo = Si es verdadero primero elimina los objetos actuales
*/
export const  reloadDatabases  =    ( reloadDatos , table = null , nuevo = 0  ) => {

    if(!db){
        console.log(db);
        return false;
    }

    if(!table){
        alert("No definio una tabla para actualziar la base de datos local");
        return false;
    }
    
    
    //db.productos.bulkPut(reloadDatos);

    let promise;


    if(db[table]){

        if(nuevo == 1 ){

            console.log("Limpiamos toda la base de datos" + nuevo);
            
            promise = db[table].clear().then(function () {
                console.log( "Deleted objects");
                console.log( "Datos a insertar en " +  table );
                console.log(reloadDatos)
                return db[table].bulkPut(reloadDatos);
                
            })
            .then(() => "ok")
            
            .catch(error => Promise.reject("error: " + error.message));
                
    
        }else{
            console.log(reloadDatos);   
            console.log("Agregamos uno nuevo")


            db[table].bulkPut(reloadDatos).then(function(lastKey) {

                console.log("Complete bulkPut Update ");
                console.log("Last raindrop's id was: " + lastKey); // Will be 100000.

                return "ok";
            })
        }
    }else{
        return "error La tabla : " + table + " No existe";
    }
    
    return promise;

}


export const  deleteIdsDixie  =    ( refIds , table = null   ) => {

    if(!db){
        console.log(db);
        return false;
    }

    if(!table){
        alert("No definio una tabla para actualziar la base de datos local");
        return false;
    }
    
    
    //db.productos.bulkPut(reloadDatos);

    let promise;


    if(db[table]){

     

        refIds.forEach(async (ref_id) => {
            try {
                await db.productos.where('id').equals(ref_id).delete();
                console.log(`Registros eliminados de la tabla productos con id ${ref_id}`);
            } catch (error) {
                console.error(`Error al eliminar registros de la tabla productos con id ${ref_id}:`, error);
            }
        });
                
    
        
    }else{
        return "error La tabla : " + table + " No existe";
    }
    
    return promise;

}









export const updateDixieOne = (id, itemReload , table ) => {


    if(!db){
        console.log(db);
        return false;
    }

    if(!table){
        alert("No definio una tabla para actualziar la base de datos local");
        return false;
    }
    
    
    //db.productos.bulkPut(reloadDatos);
    if(db[table]){

      
        db[table].update(id , itemReload).then(function(updated) {

            if  (updated){
                console.log("Exito al Actualizar.");
                setLocalStore('DixieItemsReload' , "1");
            }else{
                
                console.log("Fallo la Actualizaicone")
            }

        })
    }
    else{
        alert("La tabla : " + table + " No existe");
    }
}




