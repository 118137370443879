import { enviarPeticion } from "../../../Core/ActionDatabase";
import { getLocalstore, setLocalStore } from "../../../Core/fetchLocalStoreData";

export const traerCategorias = async() => {

    
    const HttpResponse = await enviarPeticion("productos/get_tabla_aux_productos");
    if(HttpResponse.respuesta == 1 ){            
        setLocalStore("Listcategorias" ,HttpResponse.data.grupos );
        setLocalStore("ListaMarcas" ,HttpResponse.data.marcas );
      

        if(HttpResponse.data.categoriasV2){
            setLocalStore("Listcategoriasv2" ,HttpResponse.data.categoriasV2 );
        }

        return HttpResponse.data;
        
    }else{
        alert("Error de Red")
    }

}


export const registrarCategoria = async (data) => {

    const HttpResponse = await enviarPeticion("productos/agregar_categoria" , data.valor  + "/" + data.nombre );

    if(HttpResponse.data && HttpResponse.data.id){
        return HttpResponse.data;
    }else{

        return null;
    }
}


const buildTree = (array, parentValue = '') => {
    let tree = [];

    array.forEach(category => {
        if (category.valor.startsWith(parentValue) && category.valor.length === parentValue.length + 2) {
            let hijos = buildTree(array, category.valor);
            if (hijos.length) {
                category.hijos = hijos;
            }
            tree.push(category);
        }
    });

    return tree;
};

export const categorizeByLength = (array) => {
    return buildTree(array);
};

export const agregarNuevaCategoriaLocalStore = (nuevaCategoria) => {

    // Obtener los datos del localStorage
    let datos = getLocalstore("Listcategoriasv2") || []; // Si no hay datos, inicializa como un array vacío
    
    // Crear una copia del array existente y agregar la nueva categoría
    datos.push(nuevaCategoria);
    

    // Guardar el nuevo array en el localStorage
    setLocalStore("Listcategoriasv2", datos);
}