	/*
	0=> Sin Enviar, 
	1=> sin respuesta de la DIAN
	2=> Enviado,    #Aplica para documentos Asincronos
	3=> Consultado  #Aplica para documentos Asincronos
	4=> Aceptado 
	5=> Validación en proceso  #Aplica para documentos Asincronos
	6=> Rechazado por la Dian!
	*/

import React, { useEffect, useState } from "react";



    const StatusCodeWebService = ({estado , mensaje}) => {



        const [estadoMostrar , SetEstadoMostrar] = useState();
        
        useEffect(() => { 
            console.log(estado);
            SetEstadoMostrar(Number(estado) )
        }, [])
        





        // Desglosa el contenido HTML
        const htmlContent = ( mensaje && mensaje.codigo_de_error) ? mensaje.codigo_de_error: '' ;
        
        // Función para separar los rechazos y las notificaciones
        const parseHtmlContent = (html) => {

            const parser = new DOMParser();
            const doc = parser.parseFromString(html, 'text/html');
            const rejections = [];
            const notifications = [];

            doc.querySelectorAll('p').forEach(p => {
        
                if (p.textContent.includes('Rechazo')) {
                rejections.push(p.outerHTML);
            } else if (p.textContent.includes('Notificación')) {
                notifications.push(p.outerHTML);
            }
            });

            return { rejections, notifications };
        };

        const { rejections, notifications } = parseHtmlContent(htmlContent);




        switch(estadoMostrar) {
            case 0:
                return (
                    <div className="alert alert-warning" role="alert">
                        <h3>Sin enviar</h3>
                        <i className="fa fa-paper-plane"></i>
                        <p><b>Pluma7</b> no envió el documento a la DIAN debido a que no superó las validaciones internas.</p>
                        <hr />
    
                        { (mensaje && mensaje.StatusDescription) && <p className=""  dangerouslySetInnerHTML={{ __html: mensaje.StatusDescription }}></p>}

                        { (mensaje && mensaje.mensaje) && <p className=""  dangerouslySetInnerHTML={{ __html: mensaje.mensaje }}></p>}

                    </div>
                );
            case 1:
                return (
                    <div className="alert alert-warning" role="alert">
                        <h3>Sin Respuesta de la DIAN</h3>
                        <i className="fa fa-exclamation-circle"></i>
                        <p>El WebService de la DIAN no respondió porque el tiempo de espera se agotó.</p>
                        <p><b>¡Ojo!</b> Puede que la factura se encuentre aceptada.</p>
                    </div>
                );
            case  4:
                return (
                    <div className="alert alert-success" role="alert">
                        <h3>Documento Aceptado por la DIAN</h3>
                        <i className="fa fa-check-circle"></i>
                        <p>Documento entregado y procesado exitosamente por la DIAN.</p>
                    </div>
                );

            case 6 :
               
            return (
                <div className="alert alert-danger" role="alert">
                <h3>DIAN: Rechazado</h3>
                <i className="fa fa-check-circle"></i>
                
                {mensaje.StatusDescription && <h3 className="color-white">{mensaje.StatusDescription}</h3>}

                <div className="card card-body bg-white text-white">
                    {rejections.length > 0 && (
                    <div className="rejections">
                        <h4>Rechazos</h4>
                        {rejections.map((rejection, index) => (
                         <p className="text-danger" key={index} dangerouslySetInnerHTML={{ __html: rejection }}></p>
                        ))}
                    </div>
                    )}
                    
                    {notifications.length > 0 && (
                    <div className="notifications">
                        <h4>Notificaciones</h4>
                        <p className="text-body-secondary">Las notificaciones no significan rechazo. Son solo mensajes o alertas de la DIAN para informar que alguna información no está correcta.</p>
                        {notifications.map((notification, index) => (
                        <p className="text-primary" key={index} dangerouslySetInnerHTML={{ __html: notification }}></p>
                        ))}
                    </div>
                    )}
                </div>
            </div>
        );

            default:
                return (
                    <div className="alert alert-secondary" role="alert">
                        <h1>{estadoMostrar}</h1>
                        <i className="fa fa-search"></i> No hay var.
                    </div>
                );
        }
    }
    

export default StatusCodeWebService;
