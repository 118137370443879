import React from 'react';
import { __fecha } from '../../../utils/myApp';
import InfoTercero from './InfoTercero';
import { __pluma7 } from '../../../Core/pluma7';
import StatusCodeWebService from './Dian/StatusCodeWebService';
import { confirmAlert } from 'react-confirm-alert';
import { enviarPeticion } from '../../../Core/ActionDatabase';
const Encabezado = ({document , recargarDocumento}) => {

    const { DOCUMENTO , ITEMS } = document;
    console.log(ITEMS);
    console.log(DOCUMENTO);
    

    const cambiarEstadoDianManual = () => {
        confirmAlert({
            title: 'Confirmar acción',
            message: '¿Estás seguro de que deseas cambiar el estado del documento?',
            buttons: [
                {
                    label: 'Sí',
                    onClick: () => cambiarEstadoDian()
                },

                {
                    label: 'No',
              
                },
            ]
        });
    };

    const cambiarEstadoDian = async () => {
        const httpResponse = await  enviarPeticion("documentos/actualizar_estado_dian"  ,   DOCUMENTO.CtDocumento_id + "/"+ 4   )
        console.log(httpResponse)
        if(httpResponse){

            if(httpResponse.data == true ){
                recargarDocumento()
            }
        }
    }

    return (
        <div className='container'>
          

            <div className=' row'>
                <div className='col-md-6 '>     
                    <div className='p-1 py-3 card'>
                        
                        <table style={{width : '100%'}}  className=" ">
                            <tr>
                                <th colSpan={1}>Tipo de documento </th>
                                <td  className={`text-center badge bg-${DOCUMENTO.CtTipoComprobanteFiscal_color_documento}`} >{DOCUMENTO.CtTipoComprobanteFiscal_nombre}</td>
                                <td>
                                   
                                </td>
                                <td  className={'text-end bg-primary text-white px-2'}  > 
                                    {  DOCUMENTO.CtDocumento_ct_numero_factura &&  <span> { DOCUMENTO.CtDocumento_ct_numero_factura}</span>   }

                                    {  !DOCUMENTO.CtDocumento_ct_numero_factura &&  <span> { DOCUMENTO.CtDocumento_id}</span>   }
                                </td>

                            </tr>

                            <tr>
                                <th colSpan={1}>Fecha Factura </th>
                                <td colSpan={3} className="text-start">  
                                    {__fecha.formatoFecha({ $fecha :   DOCUMENTO.CtDocumento_ct_fecha_factura   })} 
                                 </td>
                            </tr>

                            <tr>
                                <th colSpan={1}>Fecha Automatica </th>
                                <td colSpan={3} className="text-start">  
                                    {__fecha.formatoFecha({ $fecha :   DOCUMENTO.CREADO   })}
                                </td>
                            </tr>

                            {DOCUMENTO.CtTipoComprobanteFiscal_ct_master_id !== 0 && (
                            <tr>
                                <th>Estado de Factura</th>
                                <td>
                                    { __pluma7.EstadoFactura( {estado : DOCUMENTO.CtDocumento_ct_documento_estado_id , masterId : DOCUMENTO.CtTipoComprobanteFiscal_ct_master_id  })  }    
                                </td>
                            </tr>
                            )}

                            <tr>
                                <th>Tipo de Crédito</th>
                                <td>
                                    {DOCUMENTO.tipo_credito_nombre}
                                </td>
                            </tr>

                            <tr>
                                <th>Observación</th>
                                <td>{DOCUMENTO.periodo_observacion}</td>
                            </tr>

                            {DOCUMENTO.observacion_trasferencia && (
                                <tr>
                                    <th>Transferencia</th>
                                    <td>{DOCUMENTO.observacion_trasferencia}</td>
                                </tr>
                            )}

                            <tr>
                                <th>Vendedor</th>
                                <td>{DOCUMENTO.vendedor}</td>
                            </tr>

                            <tr>
                                <th>Cajero</th>
                                <td>{DOCUMENTO.Cajero}</td>
                            </tr>


                            
                            { DOCUMENTO.DIAN_documento_electornico == 1 && 
                                <>
                                    <tr>
                                        <th colSpan={2}>Informacion Electronica</th>
                                    </tr>

                                    <tr>
                                    <th>CUFE</th>
                                        <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal' }}>
                                            {DOCUMENTO.DIAN_CUFE}
                                        </td>
                                    </tr>
                                    
                                    <tr>
                                        <td  colSpan={2}>  <StatusCodeWebService estado={DOCUMENTO.DIAN_repuesta_dian} mensaje={DOCUMENTO.DIAN__responsexml} />   </td>
                                    </tr>


                                    {DOCUMENTO.DIAN_repuesta_dian == 1 && <tr>
                                        <th>Cambiar Estado</th>
                                        <td> Actualmente, Pluma7 no puede verificar si el documento fue aceptado por la DIAN. Sin embargo, si confirmas que el documento ha sido aceptado, puedes cambiar el estado manualmente haciendo clic en este botón
                                            <button onClick={() => cambiarEstadoDianManual()}>Cambiar estado Aceptado</button>
                                        </td>
                                    </tr>}
                                </>
                            }


                        

                        </table>

                    </div>     
                </div>

                <div className='col-md-6 card '>     
                    <InfoTercero DOCUMENTO={DOCUMENTO} />       
                </div>
            </div>  
        </div>
    );
};

export default Encabezado;