import React, { useEffect, useState } from 'react';
import { getLocalstore  } from '../../../Core/fetchLocalStoreData';
import { traerCategorias } from '../../Productos/Categorias/Core';

const ListaCategorias = ({filtroCategoria}) => {


    const [categorias, setcategorias] = useState([]);


    const [ nombreCategoria , setnombreCategoria  ] = useState();

    useEffect( () => {

        let data = getLocalstore("Listcategorias")
        if(!data){
            extraerCategorias();
        }else{
    
            setcategorias(data)
        }
    } , [] )


    const extraerCategorias = async () => {
        let HttpResponse = await traerCategorias();
        setcategorias(HttpResponse.data.grupos);
    } 



    const seleccionarCategoria = (item) => {
        console.log(item);
        setnombreCategoria(item.nombre)
        filtroCategoria(item);
    }




    return (
        <div>
            <h4 className='text-center mt-5 text-white'>** LISTA DE CATEGORIAS ***  
            
            
               </h4>
                    
                    <h5>{nombreCategoria}</h5>
                    
                    <>
                        {categorias && categorias.map( (item, index) => 
                            <button style={{"background" : "rgb(13 202 240 / 17%)" , "border": "0.1px solid #43cd66" }} onClick={ () => seleccionarCategoria(item) }   key={index}    href="#"  className={  "list-group-item-list-producto   list-group-item-action " } aria-current="true">
                                
                                <div className="d-flex w-100 justify-content-between mt-2">
                                    <h5 className="mb-1 producto-nombre" style={{"nombre" : "#00d4ff"}}  >{item.nombre}  </h5>
                                </div>
                                <div className="d-flex w-100 justify-content-between">
                                    <small>{/*{item.id}*/}</small>
                                    <small className='precioList' >  </small>
                                </div>
                            </button>
                        )}
                    </>
            <p className='mt-3 text-center'> -- Fin de Categorias ---  <span  onClick={traerCategorias} className='float-end link btn-link' style={{marginTop: '5px', fontSize : '10px'}}>Sincronizar</span></p>

           

        </div>
    );
};

export default ListaCategorias;