

import React from 'react';

const NumeroFactura = ({id , numfact} ) => {
    

    if(numfact){
        return (
            <span className='bg-opacity-75 badge bg-primary'>
               {numfact}
            </span>
        );
    }

    return (
        <span className=''>
           {id}
        </span>
    );
};

export default NumeroFactura;