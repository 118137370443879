import React, { useState } from 'react';
import { agregarNuevaCategoriaLocalStore, registrarCategoria } from '../Core';
import { setLocalStore } from '../../../../Core/fetchLocalStoreData';

const HijoCategoria = ({ categories, setCategories , recargarCategorias }) => {
    const [form, setForm] = useState(null);
    const [categoriaSeleccion, setCategoriaSeleccion] = useState(null);
    const [nuevoNombre, setNuevoNombre] = useState('');
    const [mensajeExito, setMensajeExito] = useState(''); // Estado para el mensaje de éxito

    const seleccionarCategoria = (category) => {
        setCategoriaSeleccion(category);
    };

    const toggleForm = (category) => {
        seleccionarCategoria(category)
        setForm(form === category.valor ? null : category.valor);
        setMensajeExito(''); // Limpiar el mensaje de éxito al mostrar el formulario
    };

    const handleAgregarSubcategoria = async (category) => {
        let nuevaSubcategoria = await extraerCodigoHttp(category);

        const agregarSubcategoria = (categorias) => {
            console.log(categorias)
            return categorias.map(cat => {
    
                if (cat.valor === categoriaSeleccion.valor) {
                    return {
                        ...cat,
                        hijos: [...(cat.hijos || []), nuevaSubcategoria]
                    };
                } else if (cat.hijos && cat.hijos.length > 0) {
                    return {
                        ...cat,
                        hijos: agregarSubcategoria(cat.hijos)
                    };
                }
                return cat;
            });
        };

        if (nuevaSubcategoria) {
            setCategories(prevCategories => agregarSubcategoria(prevCategories));
           
            agregarNuevaCategoriaLocalStore(nuevaSubcategoria)

            setNuevoNombre('');
            setForm(null); // Ocultar el formulario
            
            if(recargarCategorias){
                recargarCategorias();
            }else{
                console.log(recargarCategorias);
              
            }
            setMensajeExito('¡Categoría agregada con éxito!'); // Mostrar mensaje de éxito
        }
    };

    const extraerCodigoHttp = async (category) => {
        console.log(category);

        if (nuevoNombre.trim() === '') return;

        let nuevo = await registrarCategoria({
            valor: category.valor,
            nombre: nuevoNombre
        });

        if (nuevo) {
            console.log(nuevo);
            let copy = { ...nuevo, hijos: [] };
            return copy;
        } else {
            alert("Error, revise su internet.");
            return null;
        }
    };

    return (
        <ul className="list-group">
            {categories.map((category) => (
                <li key={category.valor} className="list-group-item">
                    <span
                        onClick={() => seleccionarCategoria(category)}
                        className='click'
                    >
                        {category.nombre} - {category.valor}
                    </span>

                    <span
                        onClick={() => toggleForm(category)}
                        className='click btn-success btn btn-sm'
                    >
                        <i className='fa fa-plus'></i>
                    </span>

                    {form === category.valor && (
                        <div>
                            <input
                                type="text"
                                placeholder="Nueva Subcategoría"
                                value={nuevoNombre}
                                onChange={(e) => setNuevoNombre(e.target.value)}
                            />
                            <button onClick={() => handleAgregarSubcategoria(category)}>Agregar</button>
                        </div>
                    )}

                    {mensajeExito && form === category.valor && (
                        <div className="alert alert-success mt-2">
                            {mensajeExito}
                        </div>
                    )}

                    {category.hijos && category.hijos.length > 0 && (
                        <ul className="list-group list-group-flush mt-2">
                            <HijoCategoria
                                categories={category.hijos}
                                setCategories={setCategories}
                                recargarCategorias={recargarCategorias}
                            />
                        </ul>
                    )}
                </li>
            ))}
        </ul>
    );
};

export default HijoCategoria;
