import React from 'react';

const ErrorCodigoNoencontrado = () => {
    return (
        <div className="text-center mt-5">
            <div className="alert alert-danger d-flex align-items-center justify-content-center" role="alert">
                <i className="fa fa-exclamation-triangle fa-3x me-3"></i>
                <h1 className="m-0" style={{ fontSize: '2rem', color: 'white' }}>
                    CÓDIGO NO ENCONTRADO
                </h1>
            </div>
        </div>
    );
};

export default ErrorCodigoNoencontrado;