import React, { useState, useEffect } from 'react';
import SelectCategorias from './SelectCategorias'; // Importar el componente SelectCategorias
import { getLocalstore } from '../../../../Core/fetchLocalStoreData';
import { categorizeByLength } from '../Core';
import StandarModal from '../../../Document/PartesDocument/StandarModal';
import Pages from '../Add/Pages';

const Render = ({getValor , defatul}) => {
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(defatul);
    
    const [showModalform, setshowModalform] = useState(null);


    useEffect(() => {
        // Simulamos la obtención de datos para propósitos de ejemplo
        cargarCategorias();
    }, []);


    const cargarCategorias = () => {

        const data = getLocalstore("Listcategoriasv2");
        const categorizedData = categorizeByLength(data);
        setCategories(categorizedData);
    }

    const onChange = (value) => {

       console.log(value)
       if(getValor){
            getValor(value)
            setSelectedCategory(value)
       }else{
          
       }
    }

    const hookNuevaCategoria = () => {
     
        cargarCategorias();
    }
    const handleCategoria = (e) => {
        e.preventDefault();
        setshowModalform(true) 
    }

    return (
        <div>
        <label><b>Categoría </b></label> <button  onClick={(e) => handleCategoria(e)} className='btn btn-primary btn-sm'>Crear nueva categoría</button>
        <SelectCategorias
            categories={categories}
            selectedValue={defatul}
            onChange={onChange}
     
        />
        <p>Valor seleccionado: {selectedCategory}</p>
        <StandarModal  size="xl" titulo="Categorias"  desactivar={() => setshowModalform(false) }  mostrar={showModalform}>
            <Pages hookNuevaCategoria={hookNuevaCategoria} />
        </StandarModal>

        </div>

     
    );
};

export default Render;

