import React, { useState } from 'react';


const SelectCategorias = ({ categories, selectedValue, onChange }) => {
   
   
    const handleSelect = (e) => {
        onChange(e.target.value);
    };

    const renderOptions = (categories, prefix = '') => {
        return categories.map((category) => (
            <React.Fragment key={category.valor}>
                <option value={category.valor}>{prefix + category.nombre}</option>
                {category.hijos && renderOptions(category.hijos, prefix + '---')}
            </React.Fragment>
        ));
    };

return (
    <select value={selectedValue} onChange={handleSelect} className="form-select">
    <option value="">Seleccionar categoría</option>
    {renderOptions(categories)}
    </select>
);
};

export default SelectCategorias;