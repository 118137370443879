import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Index from '../Productos/Index.jsx';
import IndexTerceros from '../Terceros/Index.jsx';
import Documentos from '../Documentos/LocalDocumentos/Index.jsx';
import OperadoresServicio from '../Terceros/OperadoresServicio.jsx';
import Add from '../Productos/Add.jsx';
import {  AddDocument, MainContainer } from './index.js';
import TurnoAbrir from '../Turnos/TurnoAbrir.jsx';
import TurnoCerrar from '../Turnos/TurnoCerrar.jsx';
import Error404 from './Error404.jsx';
import TurnoLista from '../Turnos/TurnoLista.jsx';
import TurnoView from '../Turnos/TurnoView.jsx';
import DisenoTirilla from '../Configuraciones/DisenoTirilla.jsx';
import DisenoCarta from '../Configuraciones/DisenoCarta.jsx';

import DisenoComanda from '../Configuraciones/DisenoComanda.jsx';
import CamposPuntoVenta from '../Configuraciones/CamposPuntoVenta.jsx';
import CocinaComanda from '../Mesas/CocinaComanda.jsx';
import Logout from '../user/Logout.jsx';
import Vendedores from '../Terceros/Vendedores.jsx';
import TerceroPerfil from '../Terceros/TerceroView/TerceroPerfil.jsx';
import Welcome from '../Pages/Welcome.jsx';
import { fetchUSer } from '../../Core/fetchLocalStoreData.js';
import RutaPrivada from '../Pages/RutaPrivada.jsx';
import {PrivateRoute} from './PrivateRoute.jsx';
import ListaDocumentos from '../Documentos/LocalDocumentos/ListaDocumentos.jsx';
import Pluma7Administrativo from '../Pages/Pluma7Administrativo.jsx';
import Pages from '../Terceros/ListTercero/Pages.jsx';
import PagesDocumentos from '../Documentos/Documentos/Pages.jsx' ;
import Parqueadero from '../Parqueadero/Panel/Pages.jsx';
import CategoriasProductos from '../Productos/Categorias/Add/Pages.jsx';






const  Rutas = (props)  => {

    
    return (
        <>
         
                <Routes>
                
                    <Route exact  element={<PrivateRoute/>}>  
                       
                        <Route  path="/Productos"      element={ <Index /> }   /> 
                        <Route  path="/categoriasproductos"      element={ <CategoriasProductos /> }   /> 
                        
                        <Route  path="/nuevoproducto"  element={ <Add /> }   />
                    
                        <Route  path="/Terceros"  element={ <Pages /> }   /> 
                        <Route  path="/TerceroPerfil/:id"  element={ <TerceroPerfil /> }   />

                        <Route  path="/OperadoresServicio"  element={ <OperadoresServicio /> }   />
                    
                        <Route path="/documentos" element={ <Documentos /> } />
                        <Route path="/parqueadero" element={ <Parqueadero /> } />

                        <Route  path="/AbrirTurno"  element={ <TurnoAbrir /> }   />
                        <Route  path="/cerrar-turno"  element={ <TurnoCerrar /> }   />
                        <Route  path="/Turnos"  element={ <TurnoLista /> }   />
                        <Route  path="/TurnosVer/:id"  element={ <TurnoView /> }   />
                        <Route  path="/disenoTirilla/"  element={ <DisenoTirilla /> }   />
                        <Route  path="/DisenoCarta/"  element={ <DisenoCarta /> }   />
                        <Route  path="/CamposPuntoVenta/"  element={ <CamposPuntoVenta /> }   />
                        <Route  path="/Comanda/:tipoCocinaComanda"  element={ <CocinaComanda /> }   />
                        <Route  path="/ComandaBarra/:tipoCocinaComanda"  element={ <CocinaComanda /> }   />
                        <Route  path="/DisenoComanda/"  element={ <DisenoComanda /> }   />
                        <Route  path="/Vendedores/"  element={ <Vendedores /> }   />
                        <Route  path="/crearDocumento/"  element={ <AddDocument /> }   />
                        <Route  path="/informe_documentos/"  element={ <PagesDocumentos /> }   />
                        
                    

                    </Route>


                    <Route  path="/*"  element={ <MainContainer /> }   /> 
                    <Route  path="/Logout/"  element={ <Logout /> }   />
                    <Route  path="/Welcome/"  element={ <Welcome /> }   />
                    <Route  path="/permisos/"  element={ <RutaPrivada /> }   /> 
                    <Route  path="/error404"  element={ <Error404 /> }   />
                    {/*
                    
                        <Route  path="/Productos"  element={ <CreateContainer /> }   /> 
                        <Route  path="/Productos"  element={ <CreateContainer /> }   /> 
                    */}  
                </Routes> 
          
        </>
    );
} 

export default Rutas;