import React, { useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { fetchUSer } from '../../../../Core/fetchLocalStoreData';
import Variantes from './Variantes';

const Pages = ({ setFormData, formData }) => {
    const [stock, setStock] = useState(null);
    const [cantidad, setCantidad] = useState(0);
    const [observacion, setObservacion] = useState("Inventario Inicial");
    const [modoVariante, setModoVariante] = useState(false); // Modo normal por defecto

    const user = fetchUSer();

    const realizarCambios = () => {
        if (cantidad === null || cantidad === undefined || cantidad.length < 1) {
            NotificationManager.error("Escriba una cantidad válida");
            return false;
        }
    };

    const setVaribleForm = (variantes) => {
        console.log(variantes);
        setData({ variantes });
    };

    const guardarInventario = (e) => {
        e.preventDefault();
        setData();
        return false;
    };

    const setData = ({ variantes } = {}) => {
        console.log(variantes);
        setFormData({
            ...formData,
            INVENTARIO: {
                producto_id: null,
                estacione_id: user.ESTACION.id,
                cantidad_actual: 0,
                user_id: user.USER.id,
                observacion: observacion,
                cantidad: cantidad,
                VARIANTES: variantes || formData.INVENTARIO?.VARIANTES || [],
            },
        });
    };

    return (
        <div className="col-md-6 col-12">
           
            {/* Switch para alternar entre modos */}
            <div className="form-check form-switch mb-3">
                <input
                    className="form-check-input"
                    type="checkbox"
                    id="modoVarianteSwitch"
                    checked={modoVariante}
                    onChange={() => setModoVariante(!modoVariante)}
                />
                <label className="form-check-label" htmlFor="modoVarianteSwitch">
                    {modoVariante ? "Modo Variante" : "Modo Normal"}
                </label>
            </div>



            

            {/* Renderizar según el modo */}
            {modoVariante ? (
                <Variantes setVaribleForm={setVaribleForm} variantesAux={formData.INVENTARIO?.VARIANTES} />
            ) : (
                <>
                    {!formData.INVENTARIO && (
                        <>
                            <div className="mt-2">
                                <label htmlFor="">Stock Inicial</label>
                                <input
                                    value={cantidad}
                                    onChange={(e) => setCantidad(e.target.value)}
                                    type="number"
                                    className="form-control"
                                />
                            </div>

                            <div className="mt-4">
                                <label htmlFor="">Escribe una Observación</label>
                                <input
                                    value={observacion}
                                    onChange={(e) => setObservacion(e.target.value)}
                                    type="text"
                                    className="form-control"
                                />
                            </div>

                            <button onClick={(e) => guardarInventario(e)} className="btn mt-2 btn-primary">
                                Guardar Inventario
                            </button>
                        </>
                    )}

                    {formData.INVENTARIO && (
                        <p>
                            Stock inicial: <b>{formData.INVENTARIO.cantidad}</b>{" "}
                            <a
                                onClick={() => setFormData({ ...formData, INVENTARIO: null })}
                                className="btn btn-sm btn-warning"
                            >
                                Editar
                            </a>
                        </p>
                    )}
                </>
            )}
        </div>
    );
};

export default Pages;
