import React, { useEffect, useState } from 'react';
import { getLocalstore } from '../../../../Core/fetchLocalStoreData';
import { categorizeByLength, traerCategorias } from '../Core';
import HijoCategoria from './HijoCategoria';
import Render from '../Select/Render';

const Pages = ({hookNuevaCategoria}) => {
    const [categories, setCategories] = useState([]);
    
    
    console.log(hookNuevaCategoria)

    useEffect(() => {
        
        const data = getLocalstore("Listcategoriasv2");
        if (data) {
            const categorizedData = categorizeByLength(data);
            setCategories(categorizedData);
        }
    }, []);

    const sincronizarCategorias = async () => {
        let data = await traerCategorias();
        if (data.categoriasV2) {
            const categorizedData = categorizeByLength(data.categoriasV2);
            setCategories(categorizedData);
        }
    };

    


    const recargarCategorias =() => {
        
        if(hookNuevaCategoria){
            hookNuevaCategoria()
        }
    
    }



    return (
        <div className="container mt-4">
            <h1>Categorías</h1>
            <button className="btn btn-primary mb-3" onClick={sincronizarCategorias}>Sincronizar</button>
        
            

            <div className='row'>
                <div className='col-4'>
                    <HijoCategoria  categories={categories} setCategories={setCategories} recargarCategorias={recargarCategorias}/>
                </div>

                <div className='col-8'>
                    
                   
                </div>
            </div>

        </div>
    );
};

export default Pages;
