import React, { useState, useEffect } from 'react';
import { __app, __fecha } from '../../../../../utils/myApp';
import { Numpositivo } from '../../../../../Core/MatematicasGlobales';

const InfoTerceroDocumentos = ({ info_sado, respuestaSi, cerrarModal }) => {
    // Estado para gestionar los saldos de anticipos y devoluciones
    const [anticipos, setAnticipos] = useState(info_sado.SaldoAdelantado);
    const [devoluciones, setDevoluciones] = useState(info_sado.infoCuentasxPagarVenta);
    const [totalFavor, setTotalFavor] = useState(0);

    // Calcular el total a favor
    useEffect(() => {
       
        const totalAnticipos = anticipos.reduce((acc, saldo) => acc + parseFloat(saldo.total), 0);
        
        let  totalDevoluciones = devoluciones.reduce((acc, saldo) => acc + parseFloat(saldo.total), 0);
        

        ///Convertimos numeros negativos a positovos-
        if(totalDevoluciones < 0){
            totalDevoluciones = totalDevoluciones * -1;
        }
       
        setTotalFavor(totalAnticipos + totalDevoluciones);
    }, [anticipos, devoluciones]);

    // Función para eliminar un saldo de anticipos
    const handleRemoveAnticipo = (id) => {
        setAnticipos(prevSaldos => prevSaldos.filter(saldo => saldo.id !== id));
    };

    // Función para eliminar un saldo de devoluciones
    const handleRemoveDevolucion = (id) => {
        setDevoluciones(prevSaldos => prevSaldos.filter(saldo => saldo.id !== id));
    };

    // Función para confirmar el uso de los saldos seleccionados
    const handleSi = () => {
        cerrarModal(false);
        respuestaSi({ anticipos, devoluciones }); // Enviar los saldos restantes
    };

    return (
        <div>
            <div className='alert alert-success'>
                <h4>Este Cliente tiene un saldo a favor</h4>
                <h1 className='mt-5'>Total a favor $ {__app.formato_decimal({ total: totalFavor })}</h1>
                <p>Puedes usar este saldo a favor como forma de pago.</p>
            </div>

            <div>
                <h3>Pagos adelantados Encontrados</h3>
                <table className="table">
                    <thead>
                        <tr>
                            <th>Código</th>
                            <th>Fecha de pago</th>
                            <th>Tipo de Pago</th>
                            <th>Total</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {anticipos.map((saldo) => (
                            <tr key={saldo.id}>
                                <td>#{saldo.id}</td>
                                <td>{__fecha.formatoFecha({ $fecha: saldo.CREADO })}</td>
                                <td>{saldo.ct_tipo_comprobante_fiscal_id}</td>
                                <td>{__app.formato_decimal({ total: saldo.total })}</td>
                                <td>
                                    <button 
                                        className='btn btn-danger' 
                                        onClick={() => handleRemoveAnticipo(saldo.id)}>
                                        Quitar
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <div>
                <h3>Devoluciones a favor del tercero</h3>
                <table className="table">
                    <thead>
                        <tr>
                            <th>Código</th>
                            <th>Fecha de pago</th>
                            <th>Tipo de Pago</th>
                            <th>Total</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {devoluciones.map((saldo) => (
                            <tr key={saldo.id}>
                                <td>#{saldo.id}</td>
                                <td>{__fecha.formatoFecha({ $fecha: saldo.CREADO })}</td>
                                <td>{saldo.ct_tipo_comprobante_fiscal_id}</td>
                                <td>{__app.formato_decimal({ total: Numpositivo(saldo.total) })}</td>
                                <td>
                                    <button 
                                        className='btn btn-danger' 
                                        onClick={() => handleRemoveDevolucion(saldo.id)}>
                                        Quitar
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <button className='btn btn-lg btn-principal' onClick={handleSi}>SI!! Usar este saldo</button>
            <button className='btn btn-secondary' onClick={() => cerrarModal(false)}>Ignorar Saldo</button>
        </div>
    );
};

export default InfoTerceroDocumentos;
