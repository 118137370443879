import { enviarPeticion } from "../../../../Core/ActionDatabase";
import { fetchEmpresa, getNit } from "../../../../Core/fetchLocalStoreData";
import { __app } from "../../../../utils/myApp";

export const HttpGetEmpresa = async(nit= null , reload = false ) => {
    

    if(nit === null){
        nit =  getNit();
    }
    const respuesta = await enviarPeticion("users/get_empresa",nit , {} , false);
    

    console.log(respuesta)
    if(respuesta.status) {


        if(respuesta.data){
            localStorage.setItem('__Ah#d7aWF___', JSON.stringify(respuesta.data));
            respuesta.data = await cargarDatosFijosEmpresa();
            return respuesta;

        }else{

            __app.alerta("Error de interno de servidor" , "warning" )
        }

    }else{
        __app.alerta("Error de Red" , "error" )
        
    }

    return respuesta;
}


export const cargarDatosFijosEmpresa  = async () => {

    var nit  = getNit();
   
    const HttmpRespuesta = await enviarPeticion("users/get_datos_basicos_precarga",nit , {} , false);

    if(HttmpRespuesta.respuesta == 1){

        var datosActuales = fetchEmpresa();
        console.log(HttmpRespuesta.data)

        let newInfo = {...datosActuales , ...HttmpRespuesta.data.data};
        localStorage.setItem('__Ah#d7aWF___', JSON.stringify(newInfo));

        return newInfo;
    
    }else{

        return null;
    }
 

}