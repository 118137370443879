import React, { useState } from 'react';
import { fetchUSer } from '../../../../../Core/fetchLocalStoreData';


const ClaveEliminarItem = ({ConfirmarEliminarItem}) => {
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const user = fetchUSer();
    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Simulación de verificación de contraseña
        const correctPassword = user.CONFIGURACIONES_TABLE.PTV_contrasena_eliminar_item_p; // Esto debería ser reemplazado por una lógica real
        if (password !== correctPassword) {
            setErrorMessage('Contraseña incorrecta');
        } else {
            setErrorMessage('');
            // Aquí puedes hacer algo con la contraseña correcta, como enviarla a un servidor
            console.log('Contraseña correcta:', password);
            ConfirmarEliminarItem()
        }
    };

    return (
        <div className="container mt-5">
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <h3>Digite la contraseña de Autorización</h3>
                    <label htmlFor="password" className="form-label">Contraseña:</label>
                    <input
                        type="password"
                        id="password"
                        className={`form-control ${errorMessage ? 'is-invalid' : ''}`}
                        value={password}
                        onChange={handlePasswordChange}
                        required
                    />
                    {errorMessage && (
                        <div className="invalid-feedback">
                            {errorMessage}
                        </div>
                    )}
                </div>
                <button type="submit" className="btn btn-primary">Eliminar</button>
            </form>
        </div>
    );
};

export default ClaveEliminarItem;
