import { useEffect, useState } from "react";

import { disenoFactura } from '../../../Core/VariablesGlobales';
import {getLocalstore , fetchEmpresa } from '../../../Core/fetchLocalStoreData'
import { __app, __fecha } from "../../../utils/myApp";
import HeaderCarta from "./Partes/HeaderCarta";
import TerceroCarta from "./Partes/TerceroCarta";
import ProductosCarta from "./Partes/ProductosCarta";
import FormaPagoCarta from "./Partes/FormaPagoCarta";
import FooterCarta from "./Partes/FooterCarta";
import DatosFacturaElectronica from "./Partes/DatosFacturaElectronica";



const PlantillaCarta = ( {documento,user , modo="produccion" , estilos } ) => {


    if(!estilos){

        let estiloGuardado = getLocalstore('disenoFacturaInitialstate');
        if(estiloGuardado){
            estilos = estiloGuardado;
        }else{
            estilos = disenoFactura;;
        }
      
    }


    const empresa =  fetchEmpresa();

    const [id, setID] = useState('pruebas');



    const sum = (obj) => {

        if (!obj){
            return false;
        }

        let sum = 0;
        for (let price of obj ) {
            sum += price.total;
        }
        return sum;
    }



    const TotalPagado        =  (documento.FORMAS_DE_PAGO) ?     sum(documento.FORMAS_DE_PAGO) : 0;
    const SaldoPendiente     =  (documento.MATEMATICAS.SUBTOTAL_3 + documento.MATEMATICAS.PROPINA) -  TotalPagado ;
    const EstadoDocumento    =  ( SaldoPendiente != 0 ) ? "Pendiente De Pago" :  "Documento Pagado";
    let cambio               =  null
   


    if(Number(documento.ESTRAS.PAGO_CON )  > 0 ){
        cambio      =  Number(documento.ESTRAS.PAGO_CON ) - documento.FORMAS_DE_PAGO[0].total;
    }

 

    documento.MATEMATICAS.PROPINA = ( documento.MATEMATICAS.PROPINA ) ? documento.MATEMATICAS.PROPINA : 0;
    
    /** Estilos */
   

    
    
   useEffect(() => {
        
        setID(modo == "pruebas" ? "EasyPrintAnd__" : "EasyPrintAnd");

    },[])
     

    let aumento = -1.5;

    const fontSize = {
        "xl" : 12 + aumento ,
        "md" : 10 + aumento ,
        "xs" : 8 + aumento ,
        "xxs" : 7 + aumento ,
    }
  

    return (
        <div >
            { (estilos && documento.TIPO_DOCUMENTO ) && 
           <div id={id} >
                
                <div style={estilos.Cartacontenedor} >

                    <div style={estilos.TirillaSubcontenedor }>
                     
                        <HeaderCarta documento={documento} user={user} empresa={empresa}  fontSize={fontSize} SaldoPendiente={SaldoPendiente} /> 

                        <TerceroCarta  documento={documento}  fontSize={fontSize} />

                        <ProductosCarta documento={documento} estilos={estilos}  fontSize={fontSize} /> 
                
            
                        <FormaPagoCarta user={user} SaldoPendiente={SaldoPendiente} TotalPagado={TotalPagado} EstadoDocumento={EstadoDocumento} documento={documento}  fontSize={fontSize} />

                    
                        {/** Campos de Factura Electronica */}    
                        { documento.RESPONSE_HTTP_FACTURA_ELECTRONICA &&  <DatosFacturaElectronica documento={documento} /> }


                        <FooterCarta user={user} documento={documento} fontSize={fontSize} />

                    </div>
                </div>         
            </div>} 
        </div>
    )};

export default PlantillaCarta