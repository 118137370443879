import React , {useEffect , useState } from 'react';
import { getLocalstore , setLocalStore } from '../../../Core/fetchLocalStoreData';
import {enviarPeticion} from '../../../Core/ActionDatabase'
import OptionSelect  from '../../../Core/Html/OptionSelect';
import InputValidation from './InputValidation';
import Listacategorias from '../Categorias/Select/Render';


const Categorias = ({formData, setFormData }) => {

    

    const getValor = (valor) => {
        setFormData( {...formData , categoria_codigo :valor } )
    }
    return (
        <div className="form-group mb-3">
            
            <Listacategorias  getValor={getValor} defatul={formData.categoria_codigo}/>
        </div>
    );
};

export default Categorias;