import React from 'react';

const Tercero = ({infoPago}) => {
    console.log(infoPago.tercero);
    return (
        <>
            {infoPago.tercero && <div style={{marginTop: 10}}>
                <p><b>Tercero</b> <br/>
                Nomre: {infoPago.tercero.CtPersona_razonSocial} <br />
                Telefono : {infoPago.tercero.CtPersona_telefono} <br />
                Direccion :{infoPago.tercero.CtPersona_direccion} <br />
                </p>
            </div>}
        </>
    );
};

export default Tercero;